<template>
  <div id="app">
    <nav>
      <div class="nav-content">
        <router-link to="/"><img class="logo" src="./assets/img/navoros_b.png" alt="navoros_logo_b"></router-link>
        <ul class="main-menu">
          <li
            v-for="item in menuItems"
            :key="item.text"
            @mouseover="showSubMenu(item)"
            @mouseleave="hideSubMenu(item)"
          >
            <router-link :to="item.link">{{ item.text }}</router-link>
            <img v-if="Object.keys(item).includes('subMenu')" class="arrow" src="./assets/img/arrow.png" alt="arrow_icon">
            <ul v-show="item.show" :class="{'on': item.show}" class="sub-menu">
              <li v-for="subItem in item.subMenu" :key="subItem.text">
                <router-link :to="subItem.link">{{ subItem.text }}</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
    <router-view class="router"/>
    <footer>
      <div class="footer-wrap">
        <img src="./assets/img/navoros_b.png" alt="" class="footer-logo">
        <ul class="other">
          <li>
            <img src="./assets/img/woman.png" alt="">
            <p>관공서 우선구매대상 여성기업</p>
          </li>
          <li>
            <img src="./assets/img/tart_b.png" alt="">
            <p>창업기업제품</p>
          </li>
        </ul>
        <ul class="footer-info">
          <li>개인정보처리방침</li>
          <li>문의하기</li>
        </ul>
        <ul class="address">
          <li>대표자 : 황영광</li>
          <li>사업자등록번호 : 842-26-01719</li>
          <li>본사 : 대구광역시 서구 달구벌대로337길 9, 5층 B02호(내당동)</li>
          <li>구미캠퍼스 : 경북 구미시 대학로 61, 국립금오공과대학교 산학협력관 409-1호</li>
          <li>대표번호 : 0504-0804-6931</li>
          <li>FAX : 0504-318-1709</li>
          <li>이메일 : gloria@navoros.com</li>
        </ul>
        <p>ⓒ 2024 나보로스 All Rights Reserved.</p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuItems: [
        {
          text: '회사소개',
          link: '/company',
          show: false,
          subMenu: [
            { text: '회사소개', link: '/company' },
            { text: 'CEO인사말', link: '/ceo' },
            { text: '오시는 길', link: '/contact' },
          ]
        },
        {
          text: '사업분야',
          link: '/drone',
          show: false,
          subMenu: [
            { text: '무인비행체', link: '/drone' },
            { text: '개발진행(HW&SW)', link: '/development' },
            { text: '시제품제작', link: '/prodDesign' },
            { text: 'PCB설계/제작', link: '/pcbProd' },
          ]
        },
        {
          text: '포트폴리오',
          link: '/portfolio',
          show: false,
        },
        {
          text: '온라인견적',
          link: '/advice',
          show: false,
        },
        {
          text: '공지사항',
          link: '/notice',
          show: false,
        },
      ]
    }
  },
  methods: {
    showSubMenu(item) {
      item.show = true;
    },
    hideSubMenu(item) {
      item.show = false;
    }
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  line-height: 1;
  font-size: 20px;
  list-style: none;
  box-sizing: border-box;
}
html {
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
html::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent;
}

#app {
  font-family: "Noto Sans KR", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  overflow: hidden;
  min-height: 100vh;
  /* color: #2c3e50; */
}
nav {
  background-color: #fff;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
}
.nav-content {
  /* outline: 1px solid green; */
  width: 1200px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-content > a {
  height: 30px;
}
.logo {
  height: 30px;
}

.arrow {
  margin-left: 5px;
}
.main-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 60px;
  height: 100%;
}

.main-menu > li {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-menu > li > a {
  color: #000;
  font-weight: bold;
  text-decoration: none;
}

.sub-menu {
  /* outline: 1px solid green; */
  position: absolute;
  top: 100%;
  left: 0;
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
  border-bottom: 4px solid #000;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
}

.sub-menu > li {
  width: 240px;
  padding: 10px 20px;
  text-align: left;
}

.sub-menu > li > a {
  color: #000;
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
  position: relative;
  display: inline-block;
  /* width: 100%; */
}

.sub-menu > li > a::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #000;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0;
}

.sub-menu > li:hover > a::after {
  transform: scaleX(1);
  transform-origin: bottom left;
  opacity: 1;
}

.main-menu > li > .sub-menu.on {
  opacity: 1;
  visibility: visible;
}
.router {
  margin-top: 80px;
}

footer {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #f8f8f8;
  text-align: left ;
}
footer p, footer li {
  font-size: 16px;
}
footer .footer-wrap {
  /* outline: 1px solid green; */
  position: relative;
  width: 1200px;
  margin: 0 auto;
}
.footer-wrap > img  {
  position: absolute;
  top: 0;
  right: 0;
  height: 34px;
} 
.footer-wrap .other {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}
.footer-wrap .other li {
  display: flex;
  align-items: center;
  gap: 5px;
}
.footer-wrap .other li img {
  height: 30px;
}
.footer-wrap .other li p {
  font-size: 24px;
  font-weight: bold;
  color: #5d5d5d;
}
.footer-wrap .footer-info {
  display: flex;
  gap: 45px;
  font-weight: bold;
  margin-bottom: 35px;
}
.footer-wrap .address {
  width: 700px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px 10px;
  margin-bottom: 25px;
}
.footer-wrap .address li::after {
  display: inline-block;
  content: '';
  width: 1px;
  height: 100%;
  border-right: 1px solid #000;
  margin-left: 10px;
}
.footer-wrap .address li:nth-child(2)::after {
  border-right: none;
}
.footer-wrap .address li:nth-child(3)::after {
  border-right: none;
}
.footer-wrap .address li:nth-child(4)::after {
  border-right: none;
  margin-right: 100px;
}
.footer-wrap .address li:last-child::after {
  border-right: none;
}

/* 태블릿 */
@media screen and (max-width: 1023px) {

}

/* 모바일 */
/* @media screen and (max-width: 767px) {
  nav {
    height: 50px;
  }
  .router {
    margin-top: 50px;
  }
} */
</style>