import Vue from "vue";
import VueRouter from "vue-router";
import MainView from "../views/MainView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "main",
    component: MainView,
  },
  {
    path: "/ceo", // ceo인사말
    name: "ceo",
    // component: () => import("../views/CEOSub.vue"),
    component: () => import("../views/MainView.vue"),
  },
  {
    path: "/company", // 회사소개
    name: "company",
    // component: () => import("../views/CompanySub.vue"),
    component: () => import("../views/MainView.vue"),
  },
  {
    path: "/contact", // 오시는 길
    name: "contact",
    // component: () => import("../views/ContactSub.vue"),
    component: () => import("../views/MainView.vue"),
  },
  {
    path: "/development", // 개발진행
    name: "development",
    // component: () => import("../views/DevSub.vue"),
    component: () => import("../views/MainView.vue"),
  },
  {
    path: "/drone", // 무인비행체
    name: "drone",
    // component: () => import("../views/DroneSub.vue"),
    component: () => import("../views/MainView.vue"),
  },
  {
    path: "/notice", // 공지사항
    name: "notice",
    // component: () => import("../views/NoticeBoard.vue"),
    component: () => import("../views/MainView.vue"),
  },
  {
    path: "/notice/:id", // 공지사항 단일글
    name: "noticeContent",
    // component: () => import("../views/NoticeBoard.vue"),
    component: () => import("../views/MainView.vue"),
  },
  {
    path: "/advice", //온라인견적
    name: "advice",
    // component: () => import("../views/OnlineAdviceBoard.vue"),
    component: () => import("../views/MainView.vue"),
  },
  {
    path: "/pcbProd", // pcb설계/제작
    name: "pcbProd",
    // component: () => import("../views/PCBProdSub.vue"),
    component: () => import("../views/MainView.vue"),
  },
  {
    path: "/portfolio", // 포트폴리오
    name: "portfolio",
    // component: () => import("../views/PortfolioBoard.vue"),
    component: () => import("../views/MainView.vue"),
  },
  {
    path: "/prodDesign", // 제품디자인(시제품제작)
    name: "prodDesign",
    // component: () => import("../views/ProdDesignSub.vue"),
    component: () => import("../views/MainView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
